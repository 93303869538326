
import { type } from 'os';

<template>
<div>
   <!-- <section class="sec-pad">
      <div class="container">
         <div class="row justify-content-center text-center">
            <div class="col-lg-6">
               <h2>Words from our Customers</h2>
               <p>Get inspired by these stories.</p>
            </div>
         </div>
         <div class="row">
            <div class="col-md-12 caro">

               <Carousel :breakpoints="slidebreakpoints" :wrap-around="true" :autoplay="2000">
                  <Slide v-for="(person,index) in persons" :key="index" class="col-lg-4 col-md-6 col-sm-12 col-12 slide" v-bind:style="{ height: '400px' }" >

                     <div class="reviewcard">
                        <div class="r-g-card shadow border">
                           <div class="reviewer-with-source media">
                              <div class="reviewer-info media">
                                 <div class="reviewerimg"><img :src="require(`../../../../core/assets/images/team/${person.comImage}`)" 
                                       alt="img"></div>
                                 <div class="reviewrdata">
                                    <h5>{{ person.name }}</h5>
                                    <p>{{ person.job }}</p>
                                 </div>
                              </div>
                              <div class="reviewsource"><img :src="require(`../../../../core/assets/images/brands/${person.personImage}`)" 
                                    alt="img"></div>
                           </div>
                           <div class="review-content mt20">
                              <p class="mt10">{{ person.content }}</p>
                           </div>
                        </div>
                     </div>
                  </Slide>
               </Carousel>
            </div>
         </div>
       </div>
   </section> --->
</div>
</template>

<script src="../js/reviewCu.js">


</script>

<style scoped>
@import url("../css/reviewCu.css");
@import url("../../layouts/css/responsive.css");
</style>