import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  
  name: "Contact",

    data() {
      return {
        name:'',
        mobile:'',
        email:'',
        interestedIn:'',
        message:'',
        locationOne: true,
        locationTwo: false,
        locationThree: false,
        locationFour: false,
        currentMonth: null,
        currentMonthName: null
      }
    },
  async mounted() {
    await this.locationjs();
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const leftMonth = 12 - currentMonth;
    if(leftMonth == 0)
    {
      this.currentMonth = 3;
    }else{
      this.currentMonth = leftMonth;
    }

    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const currentMonthIndex = currentDate.getMonth();
    this.currentMonthName = monthNames[currentMonthIndex+1];
    //this.currentMonth = currentDate.getMonth() + 1; // JavaScript months are zero-based, so we add 1 to get the actual month number
  },
  methods: {
    contact(){
      //this.$router.push("/contact");
    },
    privacyPolicy() {
      this.$router.push("/privacy-policy");
    },
    formSubmit(e) {
      e.preventDefault();
      Swal.showLoading()
      axios.post('https://daniyal.technology/identity/api/send-email', {
        name:  this.name,
        mobile: this.mobile,
        email: this.email,
        interestedIn: this.interestedIn,
        message: this.message
      })
      .then(function (response) {
        Swal.close();
          if(response.status === 200) {
            // alert("Email has been sent successfully");
            Swal.fire(
              'Email has been sent successfully!'
            )            
            document.getElementById("name").value = ''
            document.getElementById("mobile_number").value = ''
            document.getElementById("email").value = ''
            document.getElementById("interested_in").value = ''
            document.getElementById("description").value = ''
          }
      })
      .catch(function (error) {
        Swal.close();
        console.log("error",error);
      });
  },
    activeLocation(locationNum){
      if(locationNum==1)
      {
          this.locationOne=true;
          this.locationTwo=false;
          this.locationThree=false;
          this.locationFour=false;
      }
      else if(locationNum==2)
      {
          this.locationOne=false;
          this.locationTwo=true;
          this.locationThree=false;
          this.locationFour=false;
      }
      else if(locationNum==3)
      {
          this.locationOne=false;
          this.locationTwo=false;
          this.locationThree=true;
          this.locationFour=false;
      }
      else if(locationNum==4)
      {
          this.locationOne=false;
          this.locationTwo=false;
          this.locationThree=false;
          this.locationFour=true;
      }
    }
    // locationjs: function () {
    //   document.querySelector(document).ready(function () {
    //     document.querySelector("#address-1").click(function () {
    //       document.querySelector("#addressblock-1").show();
    //       document.querySelector("#addressblock-2").hide();
    //       document.querySelector("#addressblock-3").hide();
    //       document.querySelector("#addressblock-4").hide();
    //     });
    //     document.querySelector("#address-2").click(function () {
    //       document.querySelector("#addressblock-2").show();
    //       document.querySelector("#addressblock-1").hide();
    //       document.querySelector("#addressblock-3").hide();
    //       document.querySelector("#addressblock-4").hide();
    //     });
    //     document.querySelector("#address-3").click(function () {
    //       document.querySelector("#addressblock-3").show();
    //       document.querySelector("#addressblock-1").hide();
    //       document.querySelector("#addressblock-2").hide();
    //       document.querySelector("#addressblock-4").hide();
    //     });
    //     document.querySelector("#address-4").click(function () {
    //       document.querySelector("#addressblock-4").show();
    //       document.querySelector("#addressblock-1").hide();
    //       document.querySelector("#addressblock-2").hide();
    //       document.querySelector("#addressblock-3").hide();
    //     });
    //   });
    // },
  },
};



