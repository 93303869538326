import { Carousel,Slide,Navigation } from 'vue3-carousel';
import '../../../../node_modules/vue3-carousel/dist/carousel.css';
import VueAIcarousel from 'vue-ai-carousel'

export default {
    name: 'Brand',
    data(){
        return{
            brands: ["logo-5.png","logo-6.png","logo-7.png","logo-8.png","logo-9.png"],
            slidebreakpoints: {
                320: {
                  itemsToShow: 1,
                },
                576: {
                  itemsToShow: 2,
                },
                768: {
                  itemsToShow: 3,
                },
                992: {
                  itemsToShow: 4,
                },
                1192: {
                    itemsToShow: 5,
                  },
              },
        };
    },
    components: {
        Carousel,
        Slide,
        Navigation,
        VueAIcarousel,
    },
    
}