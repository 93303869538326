<template>
    <div class="head-section rg-bg-2">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="headconent">
                        <div class="navitagion">
                            <ul class="list-h-styled mb30">
                                <li><a v-on:click="home" href="#">Home</a></li>
                                <li class="active">Service Page</li>
                            </ul>
                        </div>
                        <h1>We design and develop high quality Websites & Mobile Apps ✶</h1>
                        <p class="mt20">Daniyal Technologies provides timely, high-quality solutions and services at a
                            cost that pleases our customers. We are able to offer end-to-end solutions and services
                            because to our extensive expertise of numerous technological fields Daniyal Technologies is
                            your one stop partner where you can outsource all your support services with complete peace
                            of mind about quality and reliability.</p>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="img-round-ani text-right">
                        <img src="../../../../core/assets/images/shape/sixflow.svg" alt="img"
                            class="w100px rotateanimation">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="headservicecard">
                        <div class="servicecard">
                            <a href="#">
                                <div class="scimg shadow roundimg"><img
                                        src="../../../../core/assets/images/service/service-1.jpg" alt="img"></div>
                                <div class="scdata">
                                    <h4>Web Development</h4>
                                </div>
                            </a>
                        </div>
                        <div class="servicecard">
                            <a href="#">
                                <div class="scimg shadow roundimg"><img
                                        src="../../../../core/assets/images/service/service-2.jpg" alt="img"></div>
                                <div class="scdata">
                                    <h4>App Design</h4>
                                </div>
                            </a>
                        </div>
                        <div class="servicecard">
                            <a href="#">
                                <div class="scimg shadow roundimg"><img
                                        src="../../../../core/assets/images/service/service-3.jpg" alt="img"></div>
                                <div class="scdata">
                                    <h4>Digital Marketing</h4>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="service-statistics">
                        <div class="statisticsdiv">
                            <h2 class="mb10"><span>1250</span>+</h2>
                            <p>Website Develop</p>
                        </div>
                        <div class="statisticsdiv">
                            <h2 class="mb10"><span>958</span>+</h2>
                            <p>App Develop</p>
                        </div>
                        <div class="statisticsdiv">
                            <h2 class="mb10"><span>1254</span>+</h2>
                            <p>Happy Client</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- block -->
    <section class="sec-pad">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 paragraph">
                    <span class="subtitleheading">✦ Who We Are</span>
                    <h2>Kickstart your project with Daniyal Technologies.</h2>
                    <p>Daniyal Technologies delivers quality and timely solutions and services at a price that make our
                        clients smile.</p>
                </div>
            </div>
            <div class="row mt30">
                <div class="col-lg-3 col-md-6 mt30">
                    <div class="r-g-card shadow">
                        <div class="iconblock">
                            <img src="../../../../core/assets/images/icons/key.png" alt="img">
                        </div>
                        <div class="icondata mt20">
                            <h4>Easy access</h4>
                            <p>Daniyal Technologies provides web solutions & services to help customer reach to a wider
                                customer base according to your target.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 mt30">
                    <div class="r-g-card shadow">
                        <div class="iconblock">
                            <img src="../../../../core/assets/images/icons/brush.png" alt="img">
                        </div>
                        <div class="icondata mt20">
                            <h4>Creative Design</h4>
                            <p>Each Client is different with his own ideology and vision, so should be his designs that
                                will ensure ripe results on your investment.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 mt30">
                    <div class="r-g-card shadow">
                        <div class="iconblock">
                            <img src="../../../../core/assets/images/icons/certificate.png" alt="img">
                        </div>
                        <div class="icondata mt20">
                            <h4>Open Source</h4>
                            <p>Future architectures are highly likely to be based on open source so we always seek out
                                open source solutions first for our needs.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 mt30">
                    <div class="r-g-card shadow">
                        <div class="iconblock">
                            <img src="../../../../core/assets/images/icons/binary-code.png" alt="img">
                        </div>
                        <div class="icondata mt20">
                            <h4>Clean Code</h4>
                            <p>We use clean code to build product scalable, secured, dynamic and flexible to changes
                                which will suit with ever changing business environment.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="sec-pad sw-bg-1-wave position-relative">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 paragraph text-white">
                    <span class="subtitleheading">✦ Trusted Services</span>
                    <h2>Excellent Web Design & Development Services</h2>
                    <p>Because of our natural talent for fusing the appropriate technology, strategy, and design, we can
                        create completely functional contemporary websites that truly embody your brand. Create websites
                        that are focused on the user by utilizing our distinctive web development services and our
                        knowledgeable team of developers and designers.</p>
                    <p>Daniyal Technologies provides web solutions & services to help customer reach to a wider customer
                        base.</p>
                </div>
                <div class="col-lg-6">
                    <div class="service-mockup">
                        <img src="../../../../core/assets/images/service/laptop-web-mockup.png" alt="img">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- roll text -->
    <div class="separatewebhranimation  menusw">
        <div class="marquee__inner" aria-hidden="true">
            <ul class="marquee-item-list">
                <li><span>We Build Brands</span><span class="alternate">✦</span></li>
                <li><span>Focus on Your Business</span><span class="alternate">✦</span></li>
                <li><span>Grow Your Business</span><span class="alternate">✦</span></li>
                <li><span>Build Your Audience</span><span class="alternate">✦</span></li>
                <li><span>Gain More Traffic</span><span class="alternate">✦</span></li>
                <li><span>Increase Brand Awareness</span><span class="alternate">✦</span></li>
                <li><span>Develop Global Presence</span><span class="alternate">✦</span></li>
                <li><span>We Build Brands</span><span class="alternate">✦</span></li>
                <li><span>Focus on Your Business</span><span class="alternate">✦</span></li>
                <li><span>Grow Your Business</span><span class="alternate">✦</span></li>
                <li><span>Build Your Audience</span><span class="alternate">✦</span></li>
                <li><span>Gain More Traffic</span><span class="alternate">✦</span></li>
                <li><span>Increase Brand Awareness</span><span class="alternate">✦</span></li>
                <li><span>Develop Global Presence</span><span class="alternate">✦</span></li>
            </ul>
        </div>
    </div>
    <!-- block -->
    <section class="sec-pad">
        <div class="container">
            <div class="row align-end paragraph">
                <div class="col-lg-6 ">
                    <span class="subtitleheading">✦ Service We Deliverd</span>
                    <h2>What We do</h2>
                </div>
                <div class="col-lg-6 ">
                    <p>Daniyal Technologies work on various digital solutions. Daniyal Technologies is your one stop
                        partner where you can outsource all your support services with complete peace of mind about
                        quality and reliability.</p>
                </div>
            </div>
            <div class="row mt30">
                <div class="col col-xl-4 col-md-6 col-12 mt30">
                    <div class="box-service-div r-g-card shadow">
                        <div class="bsicon"><img src="../../../../core/assets/images/icons/browser.png" alt="img"></div>
                        <div class="bstext ">
                            <h4>Research</h4>
                            <p>Putting patients at the center of the clinical research process</p>
                        </div>
                    </div>
                </div>
                <div class="col col-xl-4 col-md-6 col-12 mt30">
                    <div class="box-service-div r-g-card shadow">
                        <div class="bsicon"><img src="../../../../core/assets/images/icons/id-card.png" alt="img"></div>
                        <div class="bstext ">
                            <h4>Branding Identity</h4>
                            <p>Our proven methodology makes you brand stands out from others</p>
                        </div>
                    </div>
                </div>
                <div class="col col-xl-4 col-md-6 col-12 mt30">
                    <div class="box-service-div r-g-card shadow">
                        <div class="bsicon"><img src="../../../../core/assets/images/icons/binary-code.png" alt="img">
                        </div>
                        <div class="bstext ">
                            <h4>Development</h4>
                            <p>We are likely to adopt any skill set for the development </p>
                        </div>
                    </div>
                </div>
                <div class="col col-xl-4 col-md-6 col-12 mt30">
                    <div class="box-service-div r-g-card shadow">
                        <div class="bsicon"><img src="../../../../core/assets/images/icons/graphic-designer.png"
                                alt="img"></div>
                        <div class="bstext ">
                            <h4>UI/UX Design</h4>
                            <p>We ensure a better design that is easy to use and fast.</p>
                        </div>
                    </div>
                </div>
                <div class="col col-xl-4 col-md-6 col-12 mt30">
                    <div class="box-service-div r-g-card shadow">
                        <div class="bsicon"><img src="../../../../core/assets/images/icons/coding.png" alt="img"></div>
                        <div class="bstext ">
                            <h4>Clean Code</h4>
                            <p>We use clean code to build product scalable, secured and dynamic</p>
                        </div>
                    </div>
                </div>
                <div class="col col-xl-4 col-md-6 col-12 mt30">
                    <div class="box-service-div r-g-card shadow">
                        <div class="bsicon"><img src="../../../../core/assets/images/icons/slack.png" alt="img"></div>
                        <div class="bstext ">
                            <h4>Social Media</h4>
                            <p>Additionally, we offer strategies for the brand's social media presence.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <!--Industries block -->
    <section class="sec-pad rg-bg-5">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-lg-6 paragraphInd">
                    <h2>Industries We Worked For</h2>
                    <p>We have developed many user-centric and business-friendly game applications for our clients in
                        nearly every industry from the beginning of our journey. Including the following:</p>
                </div>
            </div>
            <div class="row mt60 justify-content-center">
                <div class="col-lg-10">
                    <div class="domain-expertise">
                        <div class="iconsetsx">
                            <div class="domn-ex-lst ">
                                <div class="ico-d-e "><img
                                        src="../../../../core/assets/images/icons/ios-app-domain-exp-heart.svg"
                                        alt="img"> </div>
                                <div class="title-d-e">
                                    <p>Healthcare <br> Service</p>
                                </div>
                            </div>
                        </div>
                        <div class="iconsetsx">
                            <div class="domn-ex-lst">
                                <div class="ico-d-e "><img
                                        src="../../../../core/assets/images/icons/ios-app-domain-exp-education.svg"
                                        alt="img"> </div>
                                <div class="title-d-e">
                                    <p>Education <br> Service</p>
                                </div>
                            </div>
                            <div class="domn-ex-lst">
                                <div class="ico-d-e"><img src="../../../../core/assets/images/icons/ecommerce-s.svg"
                                        alt="img"> </div>
                                <div class="title-d-e">
                                    <p>eCommerce <br> Service</p>
                                </div>
                            </div>
                        </div>
                        <div class="iconsetsx">
                            <div class="domn-ex-lst">
                                <div class="ico-d-e"><img
                                        src="../../../../core/assets/images/icons/ios-app-domain-exp-social.svg"
                                        alt="img"> </div>
                                <div class="title-d-e">
                                    <p>Social <br> Networking</p>
                                </div>
                            </div>
                            <div class="domn-ex-lst highlightdomain text-white">
                                <div class="ico-d-e"><img src="../../../../core/assets/images/icons/joystick-a.svg"
                                        alt="img"> </div>
                                <div class="title-d-e">
                                    <p>Mobile <br> Game</p>
                                </div>
                            </div>
                            <div class="domn-ex-lst">
                                <div class="ico-d-e"><img src="../../../../core/assets/images/icons/target-a.svg"
                                        alt="img"> </div>
                                <div class="title-d-e">
                                    <p>Sports <br> Service</p>
                                </div>
                            </div>
                        </div>
                        <div class="iconsetsx">
                            <div class="domn-ex-lst">
                                <div class="ico-d-e"><img
                                        src="../../../../core/assets/images/icons/ios-app-domain-exp-retail.svg"
                                        alt="img"> </div>
                                <div class="title-d-e">
                                    <p>Retail <br> Service</p>
                                </div>
                            </div>
                            <div class="domn-ex-lst highlightdomain text-white">
                                <div class="ico-d-e"><img
                                        src="../../../../core/assets/images/icons/ios-app-domain-exp-manufacturing.svg"
                                        alt="img">
                                </div>
                                <div class="title-d-e">
                                    <p>Manufacturing <br> Service</p>
                                </div>
                            </div>
                            <div class="domn-ex-lst">
                                <div class="ico-d-e"><img
                                        src="../../../../core/assets/images/icons/ios-app-domain-exp-energy.svg"
                                        alt="img"> </div>
                                <div class="title-d-e">
                                    <p>Energy <br> Service</p>
                                </div>
                            </div>
                        </div>
                        <div class="iconsetsx">
                            <div class="domn-ex-lst">
                                <div class="ico-d-e"><img
                                        src="../../../../core/assets/images/icons/ios-app-domain-exp-travels.svg"
                                        alt="img"> </div>
                                <div class="title-d-e">
                                    <p>Travel & <br> Hospitality</p>
                                </div>
                            </div>
                            <div class="domn-ex-lst">
                                <div class="ico-d-e"><img
                                        src="../../../../core/assets/images/icons/ios-app-domain-exp-real-estate.svg"
                                        alt="img">
                                </div>
                                <div class="title-d-e">
                                    <p>Real <br> Estate</p>
                                </div>
                            </div>
                        </div>
                        <div class="iconsetsx">
                            <div class="domn-ex-lst">
                                <div class="ico-d-e"><img
                                        src="../../../../core/assets/images/icons/ios-app-domain-exp-logistics.svg"
                                        alt="img"> </div>
                                <div class="title-d-e">
                                    <p>Logistics <br> Service</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- block -->
    <section class="sec-pad pb0">
        <div class="container">
            <div class="row align-end">
                <div class="col-lg-6 paragraph">
                    <span class="subtitleheading">✦ Quality Service</span>
                    <h2>Our Web Designing Services Different from the Rest are!</h2>
                </div>
                <div class="col-lg-6 text-left">
                    <p>Daniyal Technologies provides web solutions & services to help customer reach to a wider customer
                        base. The
                        web is a new and different medium for communication and requires a different viewpoint and skill
                        set to use it in the most effective way.</p>
                </div>
            </div>
            <div class="row mt30">
                <div class="col-lg-6 mt30">
                    <div class="featureslist">
                        <div class="media featurelist">
                            <div class="icon-div"><img src="../../../../core/assets/images/icons/tarjani.png"
                                    alt="icon"></div>
                            <div class="media-body-content">
                                <h4>User-Friendly</h4>
                                <p>We ensure User-Friendly design that is easy to use and fast. We spend significant
                                    amount of time in designing UI/UX.</p>
                            </div>
                        </div>
                        <div class="media featurelist">
                            <div class="icon-div"><img src="../../../../core/assets/images/icons/solar.png" alt="icon">
                            </div>
                            <div class="media-body-content">
                                <h4>Latest Technology</h4>
                                <p>We understand the importance of implementing the latest technology so that your
                                    business stands out from others.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 mt30">
                    <div class="featureslist">
                        <div class="media featurelist">
                            <div class="icon-div"><img src="../../../../core/assets/images/icons/meda2.png" alt="icon">
                            </div>
                            <div class="media-body-content">
                                <h4>Quality Assurance and Testing</h4>
                                <p>We have dedicated testing team to ensure who work throughout the development phases
                                    to ensure that all functionalities work properly.</p>
                            </div>
                        </div>
                        <div class="media featurelist">
                            <div class="icon-div"><img src="../../../../core/assets/images/icons/telegram.png"
                                    alt="icon"></div>
                            <div class="media-body-content">
                                <h4>Expertise in Visuality</h4>
                                <p>Our knowledge makes your website more interactive, increasing the likelihood that
                                    users will visit it regularly.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <ctaSkew />
</template>

<script src="../js/servicePage.js"></script>

<style scoped>
@import url("../css/servicePage.css");
</style>