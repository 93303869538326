import { createApp } from 'vue';
import App from './App.vue';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap"
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import router from './router/';
import axios from 'axios'
import Swal from 'sweetalert2'
library.add(faPhone);


const app = createApp(App)
app.component('fai', FontAwesomeIcon)
const server = require('../config/server.json');
app.config.globalProperties.$api = require('../config/api.json');
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$swal = Swal;
app.config.globalProperties.$server = server;

if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    app.config.globalProperties.$hostURL = server.server_staging.HOST;
    app.config.globalProperties.$serverURL = server.server_staging.SERVER_HOST;
    app.config.globalProperties.$imageURL = server.server_staging.IMAGE_URL;
}
else {
    app.config.globalProperties.$hostURL = server.server.HOST;
    app.config.globalProperties.$serverURL = server.server.SERVER_HOST;
    app.config.globalProperties.$imageURL = server.server.IMAGE_URL;
}

app.use(router)
app.mount('#app')
