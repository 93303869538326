<template>
    <!-- inner pages head -->
    <div class="head-section rg-bg-2">
         <div class="container">
            <div class="row justify-content-between align-end">
               <div class="col-lg-5">
                  <div class="headconent">
                     <div class="navitagion">
                        <ul class="list-h-styled mb30">
                           <li><a v-on:click="home" href="#">Home</a></li>
                           <li class="active">FQA</li>
                        </ul>
                     </div>
                     <h1>Accordion FAQ</h1>
                     <p>You may be wondering why your site has such a high bounce rate or why your conversion rates are so low.</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!--faq block-->
      <section class="sec-pad">
         <div class="container">
            <div class="row v-center justify-content-center">
               <div class="col-lg-12 mmt40 head-faq">
                  <span class="subtitleheading">FAQ Title</span>
                  <h2>Frequently Asked Question</h2>
                  <div class="sw-faq mt60">
                     <div class="accordion" id="accordionExample">
                        <!-- faq item 1 -->
                        <div class="accordion-item">
                                <h2 class="accordion-header" id="heading1">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-sw-1" aria-expanded="true" aria-controls="faq-sw-1">
                                        Why do I need a mobile-friendly website?
                                    </button>
                                </h2>
                                <div id="faq-sw-1" class="accordion-collapse collapse show" aria-labelledby="heading1"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Having a mobile-friendly website is crucial in today's digital age because a growing number of people use their mobile devices to access the internet. Here are some reasons why you need a mobile-friendly website:

                                        Mobile internet usage is increasing: In recent years, there has been a significant shift in how people access the internet. In 2021, 54.8% of all website traffic worldwide came from mobile devices. This trend is expected to continue, and mobile internet usage is expected to grow further in the coming years.

                                        Better user experience: Mobile users have different expectations and requirements when it comes to website design and functionality. A mobile-friendly website ensures that your website visitors have a seamless and enjoyable user experience, regardless of the device they use.

                                        Improved search engine rankings: In 2015, Google announced that it would prioritize mobile-friendly websites in its search results. This means that if your website is not mobile-friendly, it may rank lower in search engine results pages (SERPs), making it harder for potential customers to find you.

                                        Increased traffic and engagement: A mobile-friendly website can increase traffic to your site as more people will be able to find and access your content. It can also lead to increased engagement as users are more likely to stay on your site and interact with your content if it is easy to navigate and use on their mobile devices.
                                    </div>
                                </div>
                            </div>
                            <!-- faq item 2 -->
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="heading2">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-sw-2" aria-expanded="false" aria-controls="faq-sw-2">
                                        Which Technology or Tools used for Website Designing?
                                    </button>
                                </h2>
                                <div id="faq-sw-2" class="accordion-collapse collapse" aria-labelledby="heading2"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        There are various technologies and tools used for website designing. Here are some of the most common ones:

                                        HTML (Hypertext Markup Language): HTML is the basic building block of any website. It is used to structure and format content on the web page.

                                        CSS (Cascading Style Sheets): CSS is used to control the visual appearance of a website. It is used to define colors, fonts, layout, and other design elements.

                                        JavaScript: JavaScript is a programming language that is used to add interactivity and dynamic features to a website. It is often used to create animations, interactive forms, and other elements that make a website more engaging.

                                        Content Management Systems (CMS): CMS platforms such as WordPress, Drupal, and Joomla are widely used for website development. They provide an interface for managing website content and allow non-technical users to update website content easily.

                                        Front-end frameworks: Front-end frameworks such as Bootstrap, Foundation, and Materialize are pre-designed CSS and JavaScript libraries that help in developing responsive and mobile-friendly websites.

                                        Graphic design software: Graphic design software such as Adobe Photoshop, Sketch, and Figma are used to create visual designs and layouts for websites.

                                        Website builders: Website builders such as Wix, Squarespace, and Weebly provide an easy-to-use interface for building websites without coding skills.
                                    </div>
                                </div>
                            </div>
                            <!-- faq item 3 -->
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="heading3">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-sw-3" aria-expanded="false" aria-controls="faq-sw-3">
                                        What is Daniyal Technologies?
                                    </button>
                                </h2>
                                <div id="faq-sw-3" class="accordion-collapse collapse" aria-labelledby="heading3"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Daniyal Technologies is a multinational technology company that
                                        specializes in Software development-related services and products, which include
                                        software development, mobile app development, web development, game development,
                                        multimedia content development, digital marketing solutions, automation, and
                                        simulation technologies.
                                    </div>
                                </div>
                            </div>
                            <!-- faq item 4 -->
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="heading4">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-sw-4" aria-expanded="false" aria-controls="faq-sw-4">
                                        I have got an idea, how and where do I start?
                                    </button>
                                </h2>
                                <div id="faq-sw-4" class="accordion-collapse collapse" aria-labelledby="heading4"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Please share your idea with us.
                                    </div>
                                </div>
                            </div>
                            <!-- faq item 5 -->
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="heading5">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-sw-5" aria-expanded="false" aria-controls="faq-sw-5">
                                        Can you deliver my project faster?
                                    </button>
                                </h2>
                                <div id="faq-sw-5" class="accordion-collapse collapse" aria-labelledby="heading5"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Submit the proposal fast. As soon as we get the proposal we will start working
                                        and will deliver the work.
                                    </div>
                                </div>
                            </div>
                            <!-- faq item 6 -->
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="heading6">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-sw-6" aria-expanded="false" aria-controls="faq-sw-6">
                                        Why should we select Daniyal Technologies over other companies?
                                    </button>
                                </h2>
                                <div id="faq-sw-6" class="accordion-collapse collapse" aria-labelledby="heading6"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Daniyal Technologies provides Resource services to fulfill your requirements.
                                    </div>
                                </div>
                            </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
</template>

<script src="../js/faqPage.js"></script>

<style scoped>
@import url("../css/faqPage.css");
</style>