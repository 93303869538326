<template>
    <section class="sec-pad1">
         <div class="container">
            <div class="row justify-content-center text-center">
               <div class="col-lg-6">
                  <h2>Solution We Offer</h2>
                  <p class="mt15">We take pride in being a business that values its customers. With 360 solutions & services, we guarantee the quickest delivery for your success in the digital transition.</p>
               </div>
            </div>
            <div class="row mt15">
               <div class="col-lg-4 col-md-6 mt30">
                  <div class="r-g-card shadow border swhovr">
                     <div class="pair-btwn">
                        <div class="imagesicons">  <img src="../../../../core/assets/images/icons/web-design.png" alt="icon">  </div>
                     </div>
                     <div class="card-usefor">
                        <h3>Web Design </h3>
                        <p class="mt10">Our skilled designers use their knowledge of industry standards and best practices for app and online design to every project they work on.</p>
                     </div>
                     <a v-on:click="service" href="#" class="inine-btn">Learn More <i class="fa-solid fa-arrow-trend-up ml10"></i></a>
                  </div>
               </div>
               <div class="col-lg-4 col-md-6 mt30">
                  <div class="r-g-card shadow border swhovr">
                     <div class="pair-btwn">
                        <div class="imagesicons">  <img src="../../../../core/assets/images/icons/joystick.png" alt="icon">  </div>
                     </div>
                     <div class="card-usefor">
                        <h3>Game Development</h3>
                        <p class="mt10">Our game development studio develops games and designs plots behind them through fruitful game art solutions. </p>
                     </div>
                     <a v-on:click="service" href="#" class="inine-btn">Learn More <i class="fa-solid fa-arrow-trend-up ml10"></i></a>
                  </div>
               </div>
               <div class="col-lg-4 col-md-6 mt30">
                  <div class="r-g-card shadow border swhovr">
                     <div class="pair-btwn">
                        <div class="imagesicons">  <img src="../../../../core/assets/images/icons/web-programming.png" alt="icon">  </div>
                     </div>
                     <div class="card-usefor">
                        <h3>Web Development</h3>
                        <p class="mt10">Build user-centric websites using our unique web development services' processes and our skilled team of developers and designers.</p>
                     </div>
                     <a v-on:click="service" href="#" class="inine-btn">Learn More <i class="fa-solid fa-arrow-trend-up ml10"></i></a>
                  </div>
               </div>
               <div class="col-lg-4 col-md-6 mt30">
                  <div class="r-g-card shadow border swhovr">
                     <div class="pair-btwn">
                        <div class="imagesicons">  <img src="../../../../core/assets/images/icons/ecommerce.png" alt="icon">  </div>
                     </div>
                     <div class="card-usefor">
                        <h3>Ecommerce Solutions</h3>
                        <p class="mt10">We offer e-commerce services in custom-built solutions as well as packaged software customizations and implementations.</p>
                     </div>
                     <a v-on:click="service" href="#" class="inine-btn">Learn More <i class="fa-solid fa-arrow-trend-up ml10"></i></a>
                  </div>
               </div>
               <div class="col-lg-4 col-md-6 mt30">
                  <div class="r-g-card shadow border swhovr">
                     <div class="pair-btwn">
                        <div class="imagesicons">  <img src="../../../../core/assets/images/icons/blockchain.png" alt="icon">  </div>
                     </div>
                     <div class="card-usefor">
                        <h3>Blockchain Dev</h3>
                        <p class="mt10">Enjoy heightened security, efficiency, and transparency. Gain the very best in custom blockchain application development.</p>
                     </div>
                     <a v-on:click="service" href="#" class="inine-btn">Learn More <i class="fa-solid fa-arrow-trend-up ml10"></i></a>
                  </div>
               </div>
               <div class="col-lg-4 col-md-6 mt30">
                  <div class="r-g-card shadow border swhovr">
                     <div class="pair-btwn">
                        <div class="imagesicons">  <img src="../../../../core/assets/images/icons/digital-marketing.png" alt="icon">  </div>
                     </div>
                     <div class="card-usefor">
                        <h3>Digital Marketing </h3>
                        <p class="mt10">Daniyal Technologies develops unique plans for each of its clients in accordance with their requirements and objectives.</p>
                     </div>
                     <a v-on:click="service" href="#" class="inine-btn">Learn More <i class="fa-solid fa-arrow-trend-up ml10"></i></a>
                  </div>
               </div>
            </div>
         </div>
      </section>
</template>

<script src="../js/service.js">
</script>

<style scoped>
@import url("../css/service.css");
</style>