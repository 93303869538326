<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <Header/>
  <router-view />
  <!-- <about/> 
  <contact/>
  <servicePage/>
  <home/> -->
  <Footer/>
  
</template>

<script>
import Header from './components/layouts/template/header.vue';
import Footer from './components/layouts/template/footer.vue';
//import homeVue from './components/home/template/home.vue';
// import about from './components/about/template/about.vue';
// import contact from './components/contact/template/contact.vue';
// import servicePage from './components/servicePage/template/servicePage.vue';
import canonicalMixin from './canonicalMixin.js';

export default {
  name: 'App',
  mixins: [canonicalMixin],
  components: {

    Header,
    Footer,
    // home,
    // contact,
    // about,
    // servicePage,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
}
</style>
