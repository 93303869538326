import header from "@/components/layouts/template/header.vue"
import hero from "@/components/hero/template/hero.vue"
import brand from "@/components/brand/template/brand.vue"
import service from "@/components/service/template/service.vue"
import contentHome from "@/components/contentHome/template/contentHome.vue"
import features from "@/components/features/template/features.vue"
import reviewCu from "@/components/reviewCu/template/reviewCu.vue"
import blogs from "@/components/blogs/blogs.vue"
import faq from "@/components/faq/template/faq.vue"
import footer from "@/components/layouts/template/footer.vue"

export default {
    name:'home',
    components: {
        header,
        hero,
        brand,
        service,
        contentHome,
        features,
        reviewCu,
        blogs,
        faq,
        footer,
    }
}