import ctaSkew from "@/components/ctaSkew/template/ctaSkew.vue";

export default {
    name: 'servicePage',
    components: {
        ctaSkew,
    },
    methods: {
        home(){
            this.$router.push('/home')
        }
    },
}