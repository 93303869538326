

export default {
    name: 'Career',
    data(){
        return{
            careerData: [],
        }
    },
    mounted() {
        this.careerData = this.$route.params.careerID;
        console.log("careerID",this.careerData)
    },
    created() {
       
    },
    methods: {
        home(){
            this.$router.push('/home')
        },
    },
}