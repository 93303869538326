<template>
    <div class="head-section rg-bg-2">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="headconent">
                        <div class="navitagion">
                            <ul class="list-h-styled mb30">
                                <li><a v-on:click="home" href="#">Home</a></li>
                                <li class="active">Career Page</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- block -->
    <section class="sec-pad">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 paragraph">
                    <h2 class="subtitleheading">✦ Join the Conversation</h2>
                    <p>At Daniyal Technologies, you’ll help build something that encourages millions around the world to think more, do more, learn more, feel more– and maybe even laugh more.</p>
                </div>
            </div>
            <div class="row mt30">
                <template v-for="(row, index) in dataList" :key="index">
                    <div class="col-lg-4 col-md-6 mt30">
                        <div class="r-g-card shadow">
                            <div class="icondata mt20">
                                <h4>{{ row.title }}</h4>
                                <p>Vacancy:{{ row.vacancy }}</p>
                                <p>Experience:{{ row.experience }}</p>
                                <p>Deadline:{{ row.deadline }}</p>
                                <button @click="viewDetail(row)" class="r-g-btn r-g-btn-8">View Details </button>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script src="../js/careerPage.js"></script>

<style scoped>
@import url("../css/careerPage.css");
</style>