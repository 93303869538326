import { Carousel, Slide, Navigation } from "vue3-carousel";
import "../../../../node_modules/vue3-carousel/dist/carousel.css";
import VueAIcarousel from "vue-ai-carousel";
import { computed, onMounted, onUnmounted, ref } from "vue";

export default {
  name: "ReviewCu",
  async mounted() {
    await this.scrSize();
  },
  data() {
    return {
      persons: [
        {
          name: "Petey Cruiser",
          job: "Data Scientist",
          personImage: "brand-1.svg",
          comImage: "team-1.jpg",
          content:
            "Really powerful and intuitive. We were amazed at how many tools this packs under the hood. More intuitive to use than many similar products such as Spark and Canva.",
        },
        {
          name: "Petey Cruise2",
          job: "Data Scientist2",
          personImage: "brand-2.svg",
          comImage: "team-2.jpg",
          content:
            "Really powerful and intuitive. We were amazed at how many tools this packs under the hood. More intuitive to use than many similar products such as Spark and Canva.",
        },
        {
          name: "Petey Cruise3",
          job: "Data Scientis3",
          personImage: "brand-3.svg",
          comImage: "team-3.jpg",
          content:
            "Really powerful and intuitive. We were amazed at how many tools this packs under the hood. More intuitive to use than many similar products such as Spark and Canva.",
        },
      ],
      slidebreakpoints: {
        320: {
          itemsToShow: 1,
        },
        576: {
          itemsToShow: 1,
        },
        768: {
          itemsToShow: 2,
        },
        992: {
          itemsToShow: 3,
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Navigation,
    VueAIcarousel,
  },
  methods: {
    scrSize: function () {
      let windowWidth = ref(window.innerWidth);

      const onWidthChange = () => (windowWidth.value = window.innerWidth);
      onMounted(() => window.addEventListener("resize", onWidthChange));
      onUnmounted(() => window.removeEventListener("resize", onWidthChange));

      const type = computed(() => {
        if (windowWidth.value < 550) return 1;
        if (windowWidth.value >= 550 && windowWidth.value < 1200) return 2;
        if (windowWidth.value >= 1200) return 3;
        return null; // This is an unreachable line, simply to keep eslint happy.
      });
      return { type };
    },
  },
};

// export const useBreakpoints = () => {
//     let windowWidth = ref(window.innerWidth)

//     const onWidthChange = () => windowWidth.value = window.innerWidth
//     onMounted(() => window.addEventListener('resize', onWidthChange))
//     onUnmounted(() => window.removeEventListener('resize', onWidthChange))

//     const type = computed(() => {
//       if (windowWidth.value < 550) return '1'
//       if (windowWidth.value >= 550 && windowWidth.value < 1200) return '2'
//       if (windowWidth.value >= 1200) return '3'
//       return null; // This is an unreachable line, simply to keep eslint happy.
//     })
//     return {type }
//   }
