<template>
   <section class="sec-pad rg-bg-1">
      <div class="container">
         <!-- block 1 -->
         <div class="row justify-content-between vcenter">
            <div class="col-xl-5 col-lg-5">
               <h2 class="cd-headline zoom"> Here is the reason Why you should
                  <!-- <span class="cd-words-wrapper">
                     <b class="is-visible">Choose Us</b>
                     <b>Work with Us</b>                        
                     </span> -->
                  <cwd class="cd-words-wrapper">

                  </cwd>
               </h2>
               <p>We are proud to have the best team of creative thinkers and fantastic clients from across the world,
                  trusting our developing, designing, and methodology.</p>
               <div class="sw-faq inn-faq mt30">
                  <div class="accordion" id="accordionExample-001">
                     <!-- faq item 1 -->
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-a-1">
                           <button class="accordion-button" type="button" data-bs-toggle="collapse"
                              data-bs-target="#faq-sw-a-1" aria-expanded="true" aria-controls="faq-sw-a-1">
                              Globally Diverse Perspective
                           </button>
                        </h2>
                        <div id="faq-sw-a-1" class="accordion-collapse collapse show" aria-labelledby="heading-a-1"
                           data-bs-parent="#accordionExample-001">
                           <div class="accordion-body">While keeping a local connection, we have created items that have
                              influenced people and businesses on other continents. Regardless of your target market, we
                              can provide a solution that is appropriate for the situation thanks to our extensive
                              expertise working with cutting-edge technology for a variety of audiences over the past
                              ten years.
                           </div>
                        </div>
                     </div>
                     <!-- faq item 2 -->
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-a-2">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#faq-sw-a-2" aria-expanded="false" aria-controls="faq-sw-a-2">
                              Visibility And Control
                           </button>
                        </h2>
                        <div id="faq-sw-a-2" class="accordion-collapse collapse" aria-labelledby="heading-a-2"
                           data-bs-parent="#accordionExample-001">
                           <div class="accordion-body">Never experience product confusion or ignorance. Take complete
                              control of the distribution process and monitor the development of your product on-demand
                              using exclusive tools and procedures. Utilize our Project Pulse tool to track project
                              results and get more involved in the lifecycle of your product.
                           </div>
                        </div>
                     </div>
                     <!-- faq item 3 -->
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-a-3">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#faq-sw-a-3" aria-expanded="false" aria-controls="faq-sw-a-3">
                              Our Talent Colony
                           </button>
                        </h2>
                        <div id="faq-sw-a-3" class="accordion-collapse collapse" aria-labelledby="heading-a-3"
                           data-bs-parent="#accordionExample-001">
                           <div class="accordion-body">A talented group of engineers, designers,
                              strategists and thinkers who share a passion for innovation and quality. To fulfill its
                              commitment to go above and beyond customer satisfaction, LeadSoft screens potential
                              employees utilizing one of the most rigorous assessment processes in the sector.
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-xl-6 col-lg-6 mmt40">
               <div class="sol-img">
                  <img src="../../../../core/assets/images/common/3d-man-with-rocket.png" alt="img" class="w-100">
               </div>
            </div>
         </div>
      </div>
   </section>

   <section class="sec-pad">
      <div class="container">
         <div class="row justify-content-between vcenter">
            <div class="col-xl-5 col-lg-6 mt30 order2 mmt40">
               <div class="sol-img">
                  <img src="../../../../core/assets/images/common/marketing-3.png" alt="img" class="w-100">
               </div>
            </div>
            <div class="col-xl-5 col-lg-5 mt30 order1">
               <h2 class="cd-headline zoom"> For the benefit of our clients, we offer
                  <!-- <span class="cd-words-wrapper2">
                     <b class="is-visible">Solutions</b>
                     <b>Services</b>                        
                     </span> -->
                  <cwd2 class="cd-words-wrappe2">

                  </cwd2>
               </h2>
               <p>Daniyal Technologies is involved in designing, developing, implementing and maintaining business
                  application software for both domestic and overseas markets. Daniyal Technologies provides complete
                  life cycle of software development services including system analysis, use case development, system
                  architecture & design, system integration, web API development, UI/UX design, database design and
                  administration, functional and system testing, quality assurance and project management. </p>
               <p class="quotes mt30 mb40">No need to wait longer to design and develop your applications; We offer a
                  minimum package to ensure that your budget does not stop you from flying.</p>
               <a v-on:click="contact" href="#" class="r-g-btn r-g-btn-8">Contact Us </a>
            </div>
         </div>
      </div>
   </section>
</template>

<script src="../js/contentHome.js">
</script>

<style scoped>
@import url("../css/contentHome.css");
</style>