<template>
   <!-- <div>
      <loading/>
   </div> -->
   <div class="daniyal-header animation header-light alert-header fixed-top">
      <div class="container-fluid">
         <div class="menu-header">
            <div class="menu-logo">
               <div class="website-logo">
                  <a v-on:click="home" href="#" class="">
                     <img src="../../../../core/assets/images/violet-logo.png" alt="logo">
                  </a>
               </div>
            </div>
            <div class="navbar-nav">
               <nav class="navbar navbar-expand-lg chead">
                  <div class="container-fluid">
                     <div class="offcanvas offcanvas-start-lg" id="offcanvasmenu-sw" tabindex="-1" 
                        aria-labelledby="offcanvasExampleLabel">
                        <div class="offcanvas-header d-flex d-lg-none">
                           <a v-on:click="home" href="#" class="offcanvas-title" id="offcanvasExampleLabel">
                              <img src="../../../../core/assets/images/violet-logo.png" alt="logo" class="sidebarlogo">
                           </a>
                           <a href="javascript:void(0)" class="text-reset p-0" data-bs-dismiss="offcanvas"
                              aria-label="close">✖︎</a>
                        </div>
                        <div class="offcanvas-body p-lg-0">
                           <ul class="navbar-nav sw-megamenu">
                              <li class="nav-item">
                                 <router-link to="/" class="nav-link">HOME</router-link>
                                 <!-- <a v-on:click="home" class="nav-link" href="#">HOME</a> -->
                              </li>
                              <li class="nav-item">
                                 <router-link to="/about" class="nav-link">ABOUT US</router-link>
                              </li>
                              <li class="nav-item">
                                 <router-link to="/services" class="nav-link">SERVICES</router-link>
                              </li>
                              <li class="nav-item">
                                 <router-link to="/career" class="nav-link">CAREER</router-link>
                              </li>
                              <li class="nav-item">
                                 <router-link to="/faq" class="nav-link">FAQ</router-link>
                              </li>
                              <li class="nav-item">
                                 <router-link to="/contact" class="nav-link">CONTACT US</router-link>
                              </li>
                           </ul>
                        </div>
                        
                     </div>
                  </div>
               </nav>
            </div>
            <div class="daniyal-nav-cta">
               <ul class="sw-menu-sw-set">
                  <li><a href="/contact" class="r-g-btn-2 btn-head"> Get in Touch </a> </li>
                  <li class="sidebarmenuicon">
                     <a href="#offcanvasmenu-sw" data-bs-toggle="offcanvas" data-bs-target="#offcanvasmenu-sw"
                        aria-controls="offcanvasmenu-sw">
                        <i class="fa-solid fa-bars-staggered"></i>
                        <!-- <img src="../../../../node_modules/bootstrap-icons/icons/list.svg" /> -->
                     </a>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>
   
</template>

<script src="../js/header.js">
</script>
<style>
@import url("../css/header.css");
@import url("../css/responsive.css");
</style>