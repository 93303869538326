import {createRouter, createWebHistory} from 'vue-router'
import about from '@/components/about/template/about.vue';
import contact from '@/components/contact/template/contact.vue';
import servicePage from '@/components/servicePage/template/servicePage.vue';
import careerPage from '@/components/career/template/careerPage.vue';
import careerDetail from '@/components/career/template/careerDetail.vue';
import home from '../components/home/template/home.vue'
import faqPage from '@/components/faqPage/template/faqPage.vue';
import PrivacyPolicy from '@/components/others/template/PrivacyPolicy';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',
            component: home,  
        },
        {
            path: '/home',
            component: home,  
        },
        {
            path: '/about',
            component: about,
        },
        {
            path: '/career',
            component: careerPage,
        },
        {
            path: '/career/:title',
            name: 'careerDetail',
            component: careerDetail,
            props: true
        },
        {
            path: '/services',
            component: servicePage,
        },
        {
            path: '/contact',
            component: contact,
        },
        {
            path: '/faq',
            component: faqPage,
        },
        {
            path: '/privacy-policy',
            component: PrivacyPolicy,
        },
        // {
        //     path: "/identity/:id",
        //     name: "ExampleRoot",
        //     component: PrivacyPolicy,
        //     meta: {
        //         title: "Employee Information"
        //     },
        //     beforeEnter: () => {
        //         window.location.href = 'https://identity.daniyal.technology/' + {{ $route.params.id }};
        //     }
        // }
    ],
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
      },
    
});

const DEFAULT_TITLE = 'Daniyal Technologies';
router.beforeEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});


export default router