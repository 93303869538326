<template>
    <header/>
    <hero/>
    <brand/>
    <service/>
    <contentHome/>
    <features/>
    <reviewCu/>
    <blogs/>
    <faq/>
    <footer/>
</template>
<script src="../js/home.js"></script>\

<style scoped>
@import url("../css/home.css");
</style>