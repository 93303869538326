<template>
    <!-- cta skew -->
   <section class="cta-sw-skew sec-pad pt0">
      <div class="container">
         <div class="row">
            <div class="col-lg-12">
               <div class="ctapairskew">
                  <div class="ctapair-skew">
                     <div class="skew-sw-half rg-bg-4 skew-sw-left"></div>
                     <div class="skew-sw-div rg-bg-4"></div>
                     <div class="ctapaircontent">
                        <h3 class="mb15">Want to hire resources<br> to work with you?</h3>
                        <a v-on:click="contact" href="#" class="r-g-btn r-g-btn-7 mt30">Hire Now </a>
                     </div>
                  </div>
                  <div class="ctapair-skew">
                     <div class="skew-sw-half rg-bg-5 skew-sw-right"></div>
                     <div class="skew-sw-div rg-bg-5"></div>
                     <div class="ctapaircontent secTwo">
                        <h3 class="mb15">Looking for Web Development<br> & Graphic Design Solutions?</h3>
                        <a v-on:click="contact" href="#" class="r-g-btn r-g-btn-2 mt30">Talk to Expert </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!-- // -->
</template>

<script src="../js/ctaSkew.js"></script>

<style scoped>
@import url("../css/ctaSkew.css");
</style>