<template>
   <!-- inner pages head -->
   <div class="head-section rg-bg-2">
         <div class="container">
            <div class="row justify-content-between align-end">
               <div class="col-lg-5">
                  <div class="headconent">
                     <div class="navitagion">
                        <ul class="list-h-styled mb30">
                           <li><a href="index.html">Home</a></li>
                           <li class="active">Privacy Policy</li>
                        </ul>
                     </div>
                     <h1>Privacy Policy</h1>                     
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- // -->

      <!--type effect block-->
      <section class="sec-pad">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="position-stick">
                     <div class="entry-content clear" itemprop="text">
                        <p>Welcome to <a href="#">https://www.daniyaltechnologies.com</a>. We understand that privacy online is important to users of our Site, especially when conducting business. This statement governs our privacy policies with respect to those users of the Site (“Visitors”) who visit without transacting business and Visitors who register to transact business on the Site and make use of the various services offered by daniyaltechnologies (collectively, “Services”) (“Authorized Customers”).</p>
                        <p><strong>“Personally Identifiable Information”</strong></p>
                        <p>refers to any information that identifies or can be used to identify, contact, or locate the person to whom such information pertains, including, but not limited to, name, address, phone number, fax number, email address, financial profiles, social security number, and credit card information. Personally Identifiable Information does not include information that is collected anonymously (that is, without identification of the individual user) or demographic information not connected to an identified individual.</p>
                        <p><strong>What Personally Identifiable Information is collected?</strong></p>
                        <p>We may collect basic user profile information from all of our Visitors. We collect the following additional information from our Authorized Customers: the names, addresses, phone numbers and email addresses of Authorized Customers, the nature and size of the business, and the nature and size of the advertising inventory that the Authorized Customer intends to purchase or sell.</p>
                        <p><strong>What organizations are collecting the information?</strong></p>
                        <p>In addition to our direct collection of information, our third party service vendors (such as credit card companies, clearinghouses and banks) who may provide such services as credit, insurance, and escrow services may collect this information from our Visitors and Authorized Customers. We do not control how these third parties use such information, but we do ask them to disclose how they use personal information provided to them from Visitors and Authorized Customers. Some of these third parties may be intermediaries that act solely as links in the distribution chain, and do not store, retain, or use the information given to them.</p>
                        <p><strong>How does the Site use Personally Identifiable Information?</strong></p>
                        <p>We use Personally Identifiable Information to customize the Site, to make appropriate service offerings, and to fulfill buying and selling requests on the Site. We may email Visitors and Authorized Customers about research or purchase and selling opportunities on the Site or information related to the subject matter of the Site. We may also use Personally Identifiable Information to contact Visitors and Authorized Customers in response to specific inquiries, or to provide requested information.</p>
                        <p><strong>With whom may the information may be shared?</strong></p>
                        <p>Personally Identifiable Information about Authorized Customers may be shared with other Authorized Customers who wish to evaluate potential transactions with other Authorized Customers. We may share aggregated information about our Visitors, including the demographics of our Visitors and Authorized Customers, with our affiliated agencies and third party vendors. We also offer the opportunity to “opt out” of receiving information or being contacted by us or by any agency acting on our behalf.</p>
                        <p><strong>How is Personally Identifiable Information stored?</strong></p>
                        <p>Personally Identifiable Information collected by daniyaltechnologies is securely stored and is not accessible to third parties or employees of daniyaltechnologies except for use as indicated above.</p>
                        <p><strong>What choices are available to Visitors regarding collection, use and distribution of the information?</strong></p>
                        <p>Visitors and Authorized Customers may opt out of receiving unsolicited information from or being contacted by us and/or our vendors and affiliated agencies by responding to emails as instructed, or by contacting us at Dhaka Square (4th &amp; 5th Floor), Uttara Model Town, Sector 1, Road 13, Dhaka – 1230</p>
                        <p><strong>Cookies</strong></p>
                        <p>A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns.</p>
                        <p><strong>Are Cookies Used on the Site?</strong></p>
                        <p>Cookies are used for a variety of reasons. We use Cookies to obtain information about the preferences of our Visitors and the services they select. We also use Cookies for security purposes to protect our Authorized Customers. For example, if an Authorized Customer is logged on and the site is unused for more than 10 minutes, we will automatically log the Authorized Customer off. Visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using <a href="#">https://www.daniyaltechnologies.com</a>, with the drawback that certain features of website may not function properly without the aid of cookies.</p>
                        <p><strong>Cookies used by our service providers</strong></p>
                        <p>Our service providers use cookies and those cookies may be stored on your computer when you visit our website. You can find more details about which cookies are used in our cookies info page.</p>
                        <p><strong>Cookies of third party providers and tracking</strong></p>
                        <p>We use the services of other companies to optimize this website and our services. You will receive an overview of the services we use in the following section(s).</p>
                        <p><strong>A. Google Analytics</strong></p>
                        <p>This website uses Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google Analytics uses cookies, text files stored on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators, and providing other services relating to website activity and internet usage to the website operator. Your IP address, which has been transmitted by Google Analytics, will not be associated with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser. However, please note that if you do this, you may not be able to use all functions of this website. Furthermore, you can prohibit Google from collecting and analyzing the cookie generated data about your use of the website (including your IP address) by clicking here or downloading and installing the browser plugin available here&nbsp;<a href="http://tools.google.com/dlpage/gaoptout?hl=en">http://tools.google.com/dlpage/gaoptout?hl=en</a><a href="http://tools.google.com/dlpage/gaoptout?hl=en.">.</a></p>
                        <p>If you visit this website using a mobile device (e.g. a smartphone or tablet), you can refuse the future use of Google Analytics via this link. By activating the link, we will put a cookie in your browser, which signals to us that we should prevent Google Analytics from using your device. Please keep in mind that you will have to repeat the refusal process if you remove the cookies from your browser.</p>
                        <p><strong>B. Google Ads Conversion Tracking</strong></p>
                        <p>Cookies related to Google’s AdWords service are in use on this website. These are small text files saved on the user’s computer. The information generated by the cookie about the website use will be transmitted to and stored on servers in the United States by Google. Using cookies, the user behavior on a website can be analyzed and subsequently utilized to provide targeted product recommendations and advertising based on the user’s interests. Data stored in these cookies do not contain personally identifiable information.<br>
                           If you would prefer to not receive any targeted advertising, you can deactivate the use of cookies for these purposes through Google by visiting the website:&nbsp;<a href="https://www.google.com/settings/ads/">https://www.google.com/settings/ads/.</a>&nbsp;Alternatively, users can deactivate the use of cookies by third party providers by visiting the Network Advertising Initiative’s deactivation website (<a href="http://www.networkadvertising.org/choices/">http://www.networkadvertising.org/choices/</a>).
                        </p>
                        <p>Please note that Google has its own data protection policy which is independent of our own. We assume no responsibility or liability for their policies and procedures. Please read Google’s privacy policy before using this website (<a href="https://www.google.com/intl/en/policies/privacy/">https://www.google.com/intl/en/policies/privacy/</a>).</p>
                        <p><strong>C. Google Ads Remarketing</strong></p>
                        <p>This website uses Google’s remarketing technology. This technology enables users who have already visited our online services and shown interest in our services to see targeted advertising on the websites of the Google partner network. Likewise, users that are similar to the visitors of this website can be included as part of the remarketing audience.</p>
                        <p><strong>D. Facebook Conversion Tracking Pixel</strong></p>
                        <p>With your permission, this website utilizes the Conversion Tracking Pixel service of Facebook Inc. This tool allows us to follow the actions of users after they are redirected to a provider’s website by clicking on a Facebook advertisement. We are thus able to record the efficacy of Facebook advertisements for statistical and market research purposes. The collected data remain anonymous. This means that we cannot see the personal data of any individual user. However, the collected data are saved and processed by Facebook. We are informing you on this matter according to our information at this time. Facebook is able to connect the data with your Facebook account and use the data for their own advertising purposes, in accordance with Facebook’s Data Use Policy found under:&nbsp;<a href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/.</a>&nbsp;Facebook Conversion Tracking also allows Facebook and its partners to show you advertisements on and outside Facebook. In addition, a cookie will be saved onto your computer for these purposes.<br>
                           Only users over 13 years of age may give their permission.
                        </p>
                        <p>Please click here if you would like to revoke your permission:&nbsp;<a href="https://www.facebook.com/ads/website_custom_audiences/">https://www.facebook.com/ads/website_custom_audiences/</a>.</p>
                        <p><strong>How does daniyaltechnologies use login information?</strong></p>
                        <p>daniyaltechnologies uses login information, including, but not limited to, IP addresses, ISPs, and browser types, to analyze trends, administer the Site, track a user’s movement and use, and gather broad demographic information.</p>
                        <p><strong>What partners or service providers have access to Personally Identifiable Information from Visitors and/or Authorized Customers on the Site?</strong></p>
                        <p>daniyaltechnologies has entered into and will continue to enter into partnerships and other affiliations with a number of vendors. Such vendors may have access to certain Personally Identifiable Information on a need to know the basis for evaluating Authorized Customers for service eligibility. Our privacy policy does not cover their collection or use of this information. Disclosure of Personally Identifiable Information to comply with the law. We will disclose Personally Identifiable Information in order to comply with a court order or subpoena or a request from a law enforcement agency to release information. We will also disclose Personally Identifiable Information when reasonably necessary to protect the safety of our Visitors and Authorized Customers.</p>
                        <p><strong>How does the Site keep Personally Identifiable Information secure?</strong></p>
                        <p>All of our employees are familiar with our security policy and practices. The Personally Identifiable Information of our Visitors and Authorized Customers is only accessible to a limited number of qualified employees who are given a password in order to gain access to the information. We audit our security systems and processes on a regular basis. Sensitive information, such as credit card numbers or social security numbers, is protected by encryption protocols, in place to protect information sent over the Internet. While we take commercially reasonable measures to maintain a secure site, electronic communications and databases are subject to errors, tampering, and break-ins, and we cannot guarantee or warrant that such events will not take place and we will not be liable to Visitors or Authorized Customers for any such occurrences.</p>
                        <p><strong>How can Visitors correct any inaccuracies in Personally Identifiable Information?</strong></p>
                        <p>Visitors and Authorized Customers may contact us to update Personally Identifiable Information about them or to correct any inaccuracies by emailing us at info@daniyaltechnologies.com</p>
                        <p><strong>Can a Visitor delete or deactivate Personally Identifiable Information collected by the Site?</strong></p>
                        <p>We provide Visitors and Authorized Customers with a mechanism to delete/deactivate Personally Identifiable Information from the Site’s database by contacting. However, because of backups and records of deletions, it may be impossible to delete a Visitor’s entry without retaining some residual information. An individual who requests to have Personally Identifiable Information deactivated will have this information functionally deleted, and we will not sell, transfer, or use Personally Identifiable Information relating to that individual in any way moving forward.</p>
                        <p><strong>Your rights</strong></p>
                        <p>These are summarized rights that you have under data protection law</p>
                        <ul class="content" style="text-align: left;">
                           <li>The right to access</li>
                           <li>The right to rectification</li>
                           <li>The right to erasure</li>
                           <li>The right to restrict processing</li>
                           <li>The right to object to processing</li>
                           <li>The right to data portability</li>
                           <li>The right to complain to a supervisory authority</li>
                           <li>The right to withdraw consent</li>
                        </ul>
                        <p><strong>What happens if the Privacy Policy Changes?</strong></p>
                        <p>We will let our Visitors and Authorized Customers know about changes to our privacy policy by posting such changes on the Site. However, if we are changing our privacy policy in a manner that might cause disclosure of Personally Identifiable Information that a Visitor or Authorized Customer has previously requested not be disclosed, we will contact such Visitor or Authorized Customer to allow such Visitor or Authorized Customer to prevent such disclosure.</p>
                        <p><strong>Links:</strong></p>
                        <p><a href="#">https://www.daniyaltechnologies.com</a> contains links to other websites. Please note that when you click on one of these links, you are moving to another website. We encourage you to read the privacy statements of these linked sites as their privacy policies may differ from ours.</p>
                     </div>
                  </div>            
               </div>

               
            </div>
         </div>
      </section>
      <!-- // -->
   <ctaSkew />
   <!-- // -->
</template>

<script src="../js/about.js"></script>

<style scoped>
@import url("../css/about.css");
</style>