<template>
<div>
   <!-- <div class="bloghomediv sec-pad rg-bg-1">
      <div class="container">
         <div class="row">
            <div class="col-lg-12 blogsubhead">
               <span class="subtitleheading">✦ Latest from Blog</span>
            </div>
            <h2>We Love To Share Our Learnings</h2>

         </div>
         <div class="row mt30">
            <div class="col-lg-6 mt30">
               <div class="half-blog-card swhovr">
                  <div class="half-blog-img"> <a href="#"><img src="../../../core/assets/images/blogs/blogs1.png"
                           alt="blogs" class="img-fluid"> </a> </div>
                  <div class="half-blog-content">
                     <div class="blog-quick-inf mb15"><span>12 July 22</span> <span>5 Min Read</span> </div>
                        <a href="javascript:void(0)" class="mb20 btitle">Finding the best social media platform for your audience and business</a>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget	dolor. Aenean massa.</p>
                        <a href="#" class="inine-btn mt20">Read More</a>
                  </div>
               </div>
            </div>
            <div class="col-lg-6 mt30">
               <div class="paircardrow">
                  <div class="half-blog-card swhovr">
                     <div class="half-blog-content">
                        <div class="blog-quick-inf mb15"><span>18 July 22</span> <span>5 Min Read</span> </div>
                        <a href="javascript:void(0)" class="mb20 btitle">An introduction to the principles of data
                           collection and analysis in each digital channel</a>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.
                           Aenean massa. </p>
                        <a href="#" class="inine-btn mt20">Read More</a>
                     </div>
                  </div>
                  <div class="half-blog-card swhovr">
                     <div class="half-blog-content">
                        <div class="blog-quick-inf mb15"><span>25 July 22</span> <span>5 Min Read</span> </div>
                        <a href="javascript:void(0)" class="mb20 btitle">How to Elevate Brand Stories So They Pack an
                           Emotional Punch</a>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.
                           Aenean massa. </p>
                        <a href="#" class="inine-btn mt20">Read More</a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div> --->
   </div>
</template>

<script src="./js/blogs.js"></script>

<style scoped>
@import url("./css/blogs.css");
</style>