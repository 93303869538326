<template>
   <!-- inner pages head -->
   <div class="head-section rg-bg-2">
      <div class="container">
         <div class="row">
            <div class="col-lg-8">
               <div class="headconent">
                  <div class="navitagion">
                     <ul class="list-h-styled mb30">
                        <li>
                           <router-link to="/" class="nav-link">Home</router-link>
                           <!-- <a v-on:click="home" href="#">Home</a> -->
                        </li>
                        <li class="active">About Us</li>
                     </ul>
                  </div>
                  <h1 class="mb0">We are a team of effectually passionate experts to take business to new heights.
                     ✶</h1>
               </div>
            </div>
         </div>
         <div class="row vcenter mt40">
            <div class="col-lg-6 padding-right-50">
               <p>The strength of Daniyal Technologies is in understanding the client's business procedures, culture,
                  vision, and goals across industrial segments and providing highly dependable, client-oriented
                  solutions. We bring the most comprehensive team to bear on every project we take on thanks to a unique
                  combination of domain, technical, and project management professionals.</p>
               <p class="mt15">In our each project, we emphasize on innovation. Our structured team works with a
                  methodology and knowledge to innovate and deliver excellent services. We are committed to provide
                  development and tech-support teams to software and high–tech companies with the required
                  infrastructure at a competitive rate from our State of the art development centers.
               </p>
               <a href="#" class="r-g-btn r-g-btn-1 mt30">Get Started </a>
            </div>
            <div class="col-lg-6 mmt40">
               <div class="position-relative">
                  <div class="ct-index">
                     <div class="round-text">
                        <img src="../../../../core/assets/images/common/circled-text.svg" alt="logo" class="texts">
                        <img src="../../../../core/assets/images/violet-logo.png" alt="logo" class="innerlogo">
                     </div>
                  </div>
                  <div class="single-image roundimg">
                     <img src="../../../../core/assets/images/common/about.png" alt="team">
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <!-- about block -->
   <section class="sec-pad">
      <div class="container">
         <div class="row vcenter">
            <div class="col-lg-6">
               <div class="single-image roundimg">
                  <img src="../../../../core/assets/images/common/better.jpeg" alt="office">
               </div>
            </div>
            <div class=" col-lg-6 mmt40">
               <span class="subtitleheading">✦ Who We Are</span>
               <h2>We're creating a world where workplaces work better</h2>
               <p>We work with all types of companies and many different cultures and styles ranging from start-ups to
                  major companies.</p>

               <div class="r-g-featurelist mt30">
                  <div class="numbermedia media">
                     <div class="numbericons">1</div>
                     <div class="contentfornumber">
                        <h4>We provide solutions and free consultation.</h4>
                     </div>
                  </div>
                  <div class="numbermedia media">
                     <div class="numbericons">2</div>
                     <div class="contentfornumber">
                        <h4>We deliver based on your schedule Time.</h4>
                     </div>
                  </div>
                  <div class="numbermedia media">
                     <div class="numbericons">3</div>
                     <div class="contentfornumber">
                        <h4>We do provide very detailed analysis before start project.</h4>
                     </div>
                  </div>
                  <div class="numbermedia media">
                     <div class="numbericons">4</div>
                     <div class="contentfornumber">
                        <h4>All website that we build must be SEO friendly.</h4>
                     </div>
                  </div>
               </div>
               <div class="company-stat sw-bg-1-wave shadow">
                  <div class="coutner-set">
                     <div class="counterdiv">
                        <h4 class="rg-num"><span>10</span>+</h4>
                        <p>Years of<br>Experience</p>
                     </div>
                     <div class="counterdiv">
                        <h4 class="rg-num"><span>40</span>+</h4>
                        <p>Team<br>Members</p>
                     </div>
                     <div class="counterdiv">
                        <h4 class="rg-num"><span>100</span>%</h4>
                        <p>Customer<br>Satisfaction</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!-- why choose us -->
   <section class="sec-pad pt0">
      <div class="container">
         <div class="row justify-content-between">
            <div class="col-lg-5 col-sm-12">
               <div class="position-stick">
                  <h2>Why You Should Choose Us</h2>
                  <h5 class="mt15 italic">#1 App Development. <span class="light-et">We build
                        Innovative</span>, User-Friendly &amp; Results <span class="light-et">Driven Apps.</span></h5>
                  <p class="mt15">Over 80% of our clients return to do business with us. The amount of issues a customer
                     reports is our business's main performance indicator. Our entire development process is based on
                     this figure. Our clients like how we cater to their procedures or provide them our development
                     methods based on their requirements.</p>
               </div>
            </div>
            <div class="col-lg-6 col-sm-12 mmt40">
               <div class="sw-feature-list-001">
                  <div class="media featurelist shadow">
                     <div class="icon-div"><img src="../../../../core/assets/images/icons/medal.png" alt="icon"></div>
                     <div class="media-body-content">
                        <h4>100% Guaranteed Satisfaction</h4>
                        <p>We have extensive experience of working with clients and cultures all over the world.</p>
                     </div>
                  </div>
                  <div class="media featurelist shadow">
                     <div class="icon-div"><img src="../../../../core/assets/images/icons/hand.png" alt="icon"></div>
                     <div class="media-body-content">
                        <h4>Dedicated Teams</h4>
                        <p>We provide dedicated teams for every projects we work on with top talented and skilled team
                           members.</p>
                     </div>
                  </div>
                  <div class="media featurelist shadow">
                     <div class="icon-div"><img src="../../../../core/assets/images/icons/solar-energy.png" alt="icon">
                     </div>
                     <div class="media-body-content">
                        <h4>Focus on Innovation</h4>
                        <p>We help businesses become competitive with the power of digital innovation.</p>
                     </div>
                  </div>
                  <div class="media featurelist shadow">
                     <div class="icon-div"><img src="../../../../core/assets/images/icons/creative-thinking.png"
                           alt="icon"></div>
                     <div class="media-body-content">
                        <h4>Creative and Innovative Solutions</h4>
                        <p>We help businesses become competitive with the power of digital innovation.</p>
                     </div>
                  </div>
                  <div class="media featurelist shadow">
                     <div class="icon-div"><img src="../../../../core/assets/images/icons/telegram.png" alt="icon">
                     </div>
                     <div class="media-body-content">
                        <h4>Flexibility for Effctive and Efficient Execution</h4>
                        <p>We adjust with our clients' processes or provide them with our development methodology
                           according to their needs.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!-- timeline -->
   <!--- <section class="sec-pad rg-bg-1">
      <div class="container">
         <div class="row justify-content-between">
            <div class="col-lg-4">
               <div class="position-stick">
                  <span class="subtitleheading">✦ Our story</span>
                  <h2 class="mb0">The Road To Success Stories</h2>
               </div>
            </div>
            <div class="col-lg-5 subtitleheading2">
               <p class="mt15">We were not afraid to aim high. Google wasn't built in a day, and we won't reach our
                  primary goal in a short time, either. So, started by defining our passions and goals, and we'll
                  already have the makings of a definitive plan. And we won't be afraid to give it 100 percent. We have
                  set an intention and a goal, we didn't just dream about it, attacked it.</p>
            </div>
         </div>
         <div class="row mt60 justify-content-center">
            <div class="col-xl-10 col-lg-12">
               <div class="timeline timeline2">
                  <div class="timeline__wrap">
                     <div class="timeline__items">
                        <div class="timeline__item">
                           <div class="timeline__content">
                              <h4 class="yr"><small class="opacity-50">2009</small> - We Begin Our Business</h4>
                              <p class="cont">We have expanded digitally. The organisation is making excellent strides
                                 and establishing a strong presence.</p>
                              <div class="roundimg mt20"><img src="../../../../core/assets/images/common/company-1.jpg"
                                    alt="img"></div>
                           </div>
                        </div>
                        <div class="timeline__item">
                           <div class="timeline__content">
                              <h4 class="yr"><small class="opacity-50">2014</small> - New Office in USA</h4>
                              <p class="cont">We have expanded digitally. The organisation is making excellent strides
                                 and establishing a strong presence.</p>
                              <div class="roundimg mt20"><img src="../../../../core/assets/images/common/company-2.jpg"
                                    alt="img"></div>
                           </div>
                        </div>
                        <div class="timeline__item">
                           <div class="timeline__content">
                              <h4 class="yr"><small class="opacity-50">2016</small> - Continuous Steam Expands</h4>
                              <p class="cont">We have expanded digitally. The organisation is making excellent strides
                                 and establishing a strong presence.</p>
                              <div class="roundimg mt20"><img src="../../../../core/assets/images/common/company-3.jpg"
                                    alt="img"></div>
                           </div>
                        </div>
                        <div class="timeline__item">
                           <div class="timeline__content">
                              <h4 class="yr"><small class="opacity-50">2017</small> - New Office in India</h4>
                              <p class="cont">We have expanded digitally. The organisation is making excellent strides
                                 and establishing a strong presence.</p>
                              <div class="roundimg mt20"><img src="../../../../core/assets/images/common/company-4.jpg"
                                    alt="img"></div>
                           </div>
                        </div>
                        <div class="timeline__item">
                           <div class="timeline__content">
                              <h4 class="yr"><small class="opacity-50">2019</small> 55K Project Complated</h4>
                              <p class="cont">We have expanded digitally. The organisation is making excellent strides
                                 and establishing a strong presence.</p>
                              <div class="roundimg mt20"><img src="../../../../core/assets/images/common/company-5.jpg"
                                    alt="img"></div>
                           </div>
                        </div>
                        <div class="timeline__item">
                           <div class="timeline__content">
                              <h4 class="yr"><small class="opacity-50">2019</small> - Awarded for best workplace</h4>
                              <p class="cont">We have expanded digitally. The organisation is making excellent strides
                                 and establishing a strong presence.</p>
                              <div class="roundimg mt20"><img src="../../../../core/assets/images/common/company-6.jpg"
                                    alt="img"></div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section> -->

   <!-- team -->
   <!--- <section class="sec-pad">
      <div class="container">
         <div class="row justify-content-between">
            <div class="col-lg-4">
               <div class="position-stick">
                  <span class="subtitleheading">✦ Leadership</span>
                  <h2>Our Core Team</h2>
                  <p>Our leadership team couldn't work their magic without the inspiration and help of our outstanding
                     directors and their crew.</p>
               </div>
            </div>
            <div class="col-lg-7">
               <div class="row">
                  <div class="col-lg-6 col-md-6 mt30">
                     <div class="teamcard">
                        <div class="roundimg shadow"><img src="../../../../core/assets/images/team/team-1.jpg" alt="img"
                              class="w-100"></div>
                        <div class="teamdata">
                           <div class="name-position">
                              <h4>Sumit Kumar</h4>
                              <p>Founder of Rundal</p>
                           </div>
                           <div class="social-profile">
                              <a href="javascript:void(0)"><i class="fa-brands fa-twitter"></i></a>
                              <a href="javascript:void(0)"><i class="fa-brands fa-linkedin-in"></i></a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6 col-md-6 mt30">
                     <div class="teamcard">
                        <div class="roundimg shadow"><img src="../../../../core/assets/images/team/team-2.jpg" alt="img"
                              class="w-100"></div>
                        <div class="teamdata">
                           <div class="name-position">
                              <h4>Mahi Choudhary</h4>
                              <p>CTO of Rundal</p>
                           </div>
                           <div class="social-profile">
                              <a href="javascript:void(0)"><i class="fa-brands fa-twitter"></i></a>
                              <a href="javascript:void(0)"><i class="fa-brands fa-linkedin-in"></i></a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6 col-md-6 mt30">
                     <div class="teamcard">
                        <div class="roundimg shadow"><img src="../../../../core/assets/images/team/team-3.jpg" alt="img"
                              class="w-100"></div>
                        <div class="teamdata">
                           <div class="name-position">
                              <h4>Dav Patel</h4>
                              <p>Managing Director</p>
                           </div>
                           <div class="social-profile">
                              <a href="javascript:void(0)"><i class="fa-brands fa-twitter"></i></a>
                              <a href="javascript:void(0)"><i class="fa-brands fa-linkedin-in"></i></a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6 col-md-6 mt30">
                     <div class="teamcard">
                        <div class="roundimg shadow"><img src="../../../../core/assets/images/team/team-4.jpg" alt="img"
                              class="w-100"></div>
                        <div class="teamdata">
                           <div class="name-position">
                              <h4>Leyana Charh</h4>
                              <p>Business Analyst</p>
                           </div>
                           <div class="social-profile">
                              <a href="javascript:void(0)"><i class="fa-brands fa-twitter"></i></a>
                              <a href="javascript:void(0)"><i class="fa-brands fa-linkedin-in"></i></a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section> -->
   <ctaSkew />
   <!-- // -->
</template>

<script src="../js/about.js"></script>

<style scoped>
@import url("../css/about.css");
</style>