export default {
  name: "Header",
  components: {
    // loading,
  },
  async mounted() {
  },
  methods: {
    home() {
      this.$router.push("/");
    },
    about() {
      this.$router.push("/about");
    },
    services() {
      this.$router.push("/services");
    },
    contact() {
      this.$router.push("/contact");
    },
    faq() {
      this.$router.push("/faq");
    },
    
  },
};
