

export default {
    name: 'Career',
    created() {
        this.getAllData();
    },

    data(){
        return{
            dataList: [],
        }
    },
    methods: {
        home(){
            this.$router.push('/home')
        },
        getAllData: async function () {
            this.$swal.showLoading()
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.career.getAll,
            };
            await this.$axios(config).then(response => {
                this.$swal.close()
                if (response.status == "200") {
                    if ((response.data).length > 0) {
                        this.dataList = (response?.data)
                    }else{
                      this.$swal.fire({
                        icon: "info",
                        text: " Career data not available!"
                      });
                    }
                }
            }).catch(error => {
                console.log(error)
                this.$swal.close()
                this.$swal.fire({
                    icon: "error",
                    text: "Failed to process your request!"
                });
            });
        },
        viewDetail(data){
            var str = data.title;
                str = str.replace(/,?\s+/g, '-').toLowerCase();
            console.log(str)
            this.$router.push({
                name: 'careerDetail', 
                params: { 
                    title: str,
                    careerID: data 
                }
            });
            // this.$router.push({
            //     name: "careerDetail",
            //     params: {
            //         careerID: data,
            //     }
            // });
        }
    },
}