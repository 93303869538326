export default {
  name: "Footer",
  async mounted() {
    await this.collapse();
  },
  methods: {
    home() {
      this.$router.push("/home");
    },
    about() {
      this.$router.push("/about");
    },
    services() {
      this.$router.push("/services");
    },
    contact() {
      this.$router.push("/contact");
    },
    faq() {
      this.$router.push("/faq");
    },
    privacyPolicy() {
      this.$router.push("/privacy-policy");
    },
    collapse: function() {
      var coll = document.getElementsByClassName("collapsible");
      var i;
      for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function () {
          this.classList.toggle("active");
          var content = this.nextElementSibling;
          if (content.style.display === "active") {
            content.style.display = "none";
          }
          if (content.style.maxHeight) {
            content.style.maxHeight = null;
          } else {
            content.style.maxHeight = content.scrollHeight + "px";
          }
        });
      }
    },
  },
};
