<template>
   <div class="footerfive footerbg">
      <div class="container">
         <div class="row justify-content-between">
            <div class="col-lg-6">
               <div class="row justify-content-between">
                  <div class="col-lg-5 col-md-6 mt30 company-ov">
                     <a href="#" class="footerlogo-3"><img src="../../../../core/assets/images/violet-logo.png"
                           alt="img"></a>
                     <p class="mt30">Daniyal Technologies is a Software & Website and Mobile Application Development
                        service provider company. </p>
                     <!-- <a v-on:click="about" href="#" class="r-g-btn r-g-btn-7 mt30 company-btn"> Company Profile </a> -->
                  </div>
                  <div class="col-lg-5 col-md-6 fthead mt30 company-ov">
                     
                     <div class="contactnumberdiv">
                        <h5 class="mb15 linktitle">We're Available 24/7.</h5>
                        <!-- <a href="tel:+8801860748020"><i class="fa-solid fa-tty mr10"></i> +880 1860 748020</a> -->
                        <a href="mailto:info@daniyaltechnologies.com"><i class="fa-solid fa-envelope mr10"></i> 
                           <span class="__cf_email__"
                              data-cfemail="mailto:contact@daniyaltechnologies.com"> Email Us</span></a>
                        <a href="/contact"><i class="fa-solid fa-envelope mr10"></i> 
                           <span> Contact Us</span></a>
                        <a href="mailto:career@daniyaltechnologies.com"><i class="fa-solid fa-envelope mr10"></i> 
                           <span class="__cf_email__"
                              data-cfemail="mailto:career@daniyaltechnologies.com"> Apply for career</span></a>
                     </div>
                     <div class="infobblk mt30">
                        <h5>Follow Us</h5>
                        <ul class="socialmedia">
                           <li><a target="_blank" href="https://www.facebook.com/daniyal.technology"><i class="fa-brands fa-facebook"></i></a></li>
                           <!-- <li><a href="javascript:void(0)"><i class="fa-brands fa-twitter"></i></a></li> -->
                           <li><a target="_blank"  href="https://www.linkedin.com/company/daniyal-technologies"><i class="fa-brands fa-linkedin"></i></a></li>
                           <!-- <li><a href="javascript:void(0)"><i class="fa-brands fa-instagram"></i></a></li>
                           <li><a href="javascript:void(0)"><i class="fa-brands fa-youtube"></i></a></li> -->
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-lg-5 fthead link-hover">
               <div class="row justify-content-between">
                  <div class="col-lg-5 col-md-5 mt30 footer-item1">
                     <h5 class="collapsible">Company Link</h5>
                     <ul class="footer-links-list content">
                        <li><a  href="/about">About Us </a></li>
                        <li><a  href="/services">Services </a></li>
                        <!-- <li><a href="/contact">Contact Us</a></li> -->
                        <li><a @click="privacyPolicy" href="javascript:void(0)">Privacy Policy</a></li>
                        <li><a href="/faq">Faq</a></li>
                        <!-- <li><a href="javascript:void(0)">Sitemap</a></li> -->
                     </ul>
                  </div>
                  <div class="col-lg-5 col-md-5 mt30 mm0 footer-item1">
                     <h5 class="collapsible">Our Services</h5>
                     <ul class="footer-links-list content">
                        <li><a v-on:click="services" href="javascript:void(0)">Web Design </a></li>
                        <li><a v-on:click="services" href="javascript:void(0)">Game Development </a></li>
                        <li><a v-on:click="services" href="javascript:void(0)">Ecommerce Solutions </a></li>
                        <li><a v-on:click="services" href="javascript:void(0)">Web Development </a></li>
                        <li><a v-on:click="services" href="javascript:void(0)">Digital Marketing </a></li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="footer-end border-top-dashed">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="ft-copyright text-center">
                     <p>© 2024 Daniyal Technologies. All Rights Reserved.</p>
                  </div>
               </div>
               <!-- <div class="col-lg-7">
                  <div class="ft-linkz link-hover company-ov">
                     <a href="#" @click="privacyPolicy">Privacy Policy </a>
                  </div>
               </div> -->
            </div>
         </div>
      </div>
   </div>
</template>

<script src="../js/footer.js">
</script>

<style scoped>
@import url("../css/footer.css");
@import url("../css/responsive.css");
</style>