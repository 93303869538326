<template>
      <div class="brands-sets">
            <div class="container">
                  <div class="brand-view">
                        <!-- <VueAIcarousel :Property="{ ID: 'One', Dlay: 1000, NavBtn: 'naveBtnStyle', CountPos: 'CounterPosition', CounterStyle: 'counterNewStyle' }">
                              <div class="cards"><img src="../../../../core/assets/images/brands/logo-5.png" alt="img">
                              </div>
                              <div class="cards"><img src="../../../../core/assets/images/brands/logo-6.png" alt="img">
                              </div>
                              <div class="cards"><img src="../../../../core/assets/images/brands/logo-7.png" alt="img">
                              </div>
                              <div class="cards"><img src="../../../../core/assets/images/brands/logo-8.png" alt="img">
                              </div>
                              <div class="cards"><img src="../../../../core/assets/images/brands/logo-9.png" alt="img">
                              </div>
                              <div class="cards"><img src="../../../../core/assets/images/brands/logo-5.png" alt="img">
                              </div>
                              <div class="cards"><img src="../../../../core/assets/images/brands/logo-6.png" alt="img">
                              </div>
                              <div class="cards"><img src="../../../../core/assets/images/brands/logo-7.png" alt="img">
                              </div>
                              <div class="cards"><img src="../../../../core/assets/images/brands/logo-8.png" alt="img">
                              </div>
                              <div class="cards"><img src="../../../../core/assets/images/brands/logo-9.png" alt="img">
                              </div>
                        </VueAIcarousel> -->

                        <Carousel :breakpoints="slidebreakpoints" :wrap-around="true" :autoplay="2000">
                              <Slide v-for="(index) in brands" :key="index">
                                    <div class="logo--inner-div"> <img
                                                :src="require(`../../../../core/assets/images/brands/${index}`)"
                                                alt="img"> </div>
                              </Slide>
                        </Carousel>
                  </div>
            </div>
      </div>
</template>
<script src="../js/brand.js">
</script>
<style>
@import url("../css/brand.css");
</style>