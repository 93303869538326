<template>
    <!-- inner pages head -->
    <div class="head-section sw-bg-2-wave">
        <div class="container">
            <div class="row mb80">
                <div class="col-lg-7">
                    <div class="headconent text-white">
                        <span class="subtitleheading">✦ Contact Us</span>
                        <h1 class="text-white">We're here to support you. Contact Us ✶</h1>
                    </div>
                </div>
                <div class="col-lg-4 position-relative">
                    <img src="../../../../core/assets/images/common/retro-phone.png" alt="img" class="retrophne">
                </div>
            </div>
        </div>
    </div>
    <!-- contact us block -->
    <section class="contact-block-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="contactusblockpair-1 shadow mb80 roundimg">
                        <div class="formblockz">
                            <div class="form-block">
                                <h3>Send us a message</h3>
                                <form @submit="formSubmit">
                                    <div class="fieldsets row">
                                        <div class="col-md-6 form-group floating-label">
                                            <input type="text" placeholder=" " required="required" id="name"
                                                class="floating-input" v-model="name">
                                            <label>Your Name*</label>
                                        </div>
                                        <div class="col-md-6 form-group floating-label">
                                            <input type="tel" placeholder=" " required="required" id="mobile_number"
                                                class="floating-input" v-model="mobile">
                                            <label>Mobile Number*</label>
                                        </div>
                                    </div>
                                    <div class="fieldsets row">
                                        <div class="col-md-6 form-group floating-label">
                                            <input type="email" placeholder=" " required="required" id="email"
                                                class="floating-input" v-model="email">
                                            <label>Email Address*</label>
                                        </div>
                                        <div class="col-md-6 form-group floating-label">
                                            <select required="required" id="interested_in" class="floating-select"
                                            v-model="interestedIn">
                                                <option value="">&nbsp;</option>
                                                <option value="Graphic Design">Graphic Design</option>
                                                <option value="Web Design">Web Design</option>
                                                <option value="App Design">App Design</option>
                                                <option value="Other">Other</option>
                                            </select>
                                            <label>Interested In*</label>
                                        </div>
                                    </div>
                                    <div class="fieldsets row">
                                        <div class="col-md-12 form-group floating-label">
                                            <textarea placeholder=" " required="required" id="description"
                                                class="floating-input" v-model="message"></textarea>
                                            <label>Brief about the project*</label>
                                        </div>
                                    </div>
                                    <div class="formfooter">
                                        <div class="fieldsets"> <button
                                                class="r-g-btn r-g-btn-1"><span>Submit</span> </button> </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="agree" name="agree"
                                                checked="checked">
                                            <label class="custom-control-label ctmlabl" for="agree">By clicking here, I
                                                state that I have read and understood the <a
                                                @click="privacyPolicy"  href="javascript:void(0)">Terms &amp; Conditions</a>.</label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="infoblockz sw-bg-3-wave">
                            <div class="contactinfodivv">
                                <div class="con-block-sw-div">
                                    <h2 class="mb10">Let's get in touch</h2>
                                    <p>We will catch you as early as we receive the message</p>
                                    <div class="contactinfodivsw mt30">
                                        <div class="contactnumberdiv">
                                            <p class="mb5 linktitle">We're Available 24/7. Call Now.</p>
                                            <!-- <a href="tel:+8801860748020"><i class="fa-solid fa-tty mr10"></i> +880 1860 748020</a> -->
                                        </div>
                                        <div class="contactnumberdiv mt30">
                                            <p class="mb5 linktitle">Send Us an Email:</p>
                                            <a href="mailto:contact@daniyaltechnologies.com"><i class="fa-solid fa-envelope mr10"></i> <span
                                                    class="__cf_email__"
                                                    data-cfemail="82eaf0c2fbedf7f0f5e7e0f1ebf6e7ace1edef">&nbsp; contact@daniyaltechnologies.com</span></a>
                                            <br />
                                        </div>
                                        <div class="contactnumberdiv mt30">
                                            <p class="mb5 linktitle">
                                                Send Your Resume For Career: <br />
                                                Mention Your Area Of Expertise Like : <br />
                                                <small>1. Applying for Full Stack Developer position 
                                                    (<span class="blinking-text" style="color: #ef0808; text-decoration: bli;">Open</span>) 

                                                </small><br />
                                                <small>2. Applying for SEO Specialist position 
                                                    (<span class="blinking-text" style="color: #ef0808; text-decoration: bli;">Open</span>) 
                                                </small><br />
                                            </p>
                                            <a href="mailto:career@daniyaltechnologies.com"><i class="fa-solid fa-envelope mr10"></i> <span
                                                    class="__cf_email__"
                                                    data-cfemail="82eaf0c2fbedf7f0f5e7e0f1ebf6e7ace1edef">&nbsp; career@daniyaltechnologies.com</span></a>
                                            <br />
                                        </div>
                                        <!--- <div class="contactnumberdiv mt30">
                                            <p class="mb5 linktitle">Chat on Skype:</p>
                                            <a href="#"><i class="fa-brands fa-skype mr10"></i>daniyal:live</a>
                                        </div> --->
                                        <div class="social-link-sw mt30">
                                            <span class="linktitle">Follow Us</span>
                                            <ul class="footer-social-sw mt10 sw-hover-2">
                                                <!--- <li><a href="#"><i class="fab fa-twitter" aria-hidden="true"></i></a></li> --->
                                                <li> <a href="https://www.facebook.com/daniyal.technology" target="_blank"> <i class="fab fa-facebook-f" aria-hidden="true"></i> </a></li>
                                                <!--- <li><a href="#"><i class="fab fa-youtube" aria-hidden="true"></i> </a></li> --->
                                                <li><a href="https://www.linkedin.com/company/daniyal-technologies" target="_blank">
                                                    <i class="fab fa-linkedin-in" aria-hidden="true"></i></a> 
                                                </li>
                                                <!--- <li> <a href="#"> <i class="fab fa-instagram" aria-hidden="true"></i></a>  </li>--->
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Feature Points -->
    <section class="why-choose-us sec-pad pt0">
        <div class="container">
            <div class="row ">
                <div class="col-lg-6 paragraph">
                    <h2>More reasons companies around the world chooses Daniyal</h2>
                </div>
                    <p class="mt15">Daniyal Technologies is a Software & Website and Mobile Application Development
                        service provider company. Not just a software company but we have gained the faith
                        of customers and positioned as one of the best software development companies. We
                        have an excellent team of experts who are highly skilled in software architects, software
                        engineers, and determined solid growth in the web solution market. Our main focus is 100% client
                        satisfaction by delivering creative and reliable solutions according to our client’s need. Our
                        vision is to become the number 1 software development company by providing End to
                        End software solution with a strong global footprint. We wish to make a positive mark on
                        people’s lives with our software technology. </p>
                
            </div>
            <div class="row mt30">
                <div class="col-lg-3 mt30">
                    <div class="r-g-card1 rgnl1 shadow unniqbg ">
                        <div class="icondiv"><img src="../../../../core/assets/images/icons/target.png" alt="img"></div>
                        <div class="datadiv">
                            <h3><span>Target Audience</span><br /> 20.5K</h3>
                            <p>We provide solutions and free consultation.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 mt30">
                    <div class="r-g-card1 rgnl2 shadow unniqbg">
                        <div class="icondiv"><img src="../../../../core/assets/images/icons/discount.png" alt="img">
                        </div>
                        <div class="datadiv">
                            <h3><span>Growth in Sale</span><br /> 98.2%</h3>
                            <p>We provide solutions and free consultation.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 mt30">
                    <div class="r-g-card1 rgnl3 shadow unniqbg">
                        <div class="icondiv"><img src="../../../../core/assets/images/icons/transaction.png" alt="img">
                        </div>
                        <div class="datadiv">
                            <h3><span>Money Saving</span><br /> 20.5k</h3>
                            <p>We provide solutions and free consultation.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 mt30">
                    <div class="r-g-card1 rgnl4 shadow unniqbg">
                        <div class="icondiv"><img src="../../../../core/assets/images/icons/marketings.png" alt="img">
                        </div>
                        <div class="datadiv">
                            <h3><span>Advertising Campaign</span><br /> 75.2K</h3>
                            <p>We provide solutions and free consultation.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- location map -->
    <section class="come-meet-us sec-pad pt0">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-lg-6 paragraphInd">
                    <h2>Come meet us</h2>
                    <p class="mt15">Daniyal Technologies is a Software & Website and Mobile Application Development
                        service provider company.</p>
                </div>
            </div>
            <div class="row justify-content-center mt30">
                <div class="col-lg-12">
                    <!-- address map -->
                    <div class="worldmapwithaddress">
                        <div class="address-divi-sw">
                            <div class="ripple adv-1" id="address-1" @click="activeLocation(1)"></div>
                            <div class="ripple adv-2" id="address-2" @click="activeLocation(2)"></div>
                            <div class="ripple adv-3" id="address-3" @click="activeLocation(3)"></div>
                            <div class="ripple adv-4" id="address-4" @click="activeLocation(4)"></div>
                        </div>
                    </div>
                </div>
                <!--  -->

                <!-- address data -->
                <div class="col-xl-5 col-lg-6 addressblockdiv">
                    <div id="addressblock-1" v-if="locationOne==true">
                        <div class="media r-g-card shadow">
                            <div class="icon-div"><img src="../../../../core/assets/images/icons/usa.svg" alt="icon">
                            </div>
                            <div class="media-body-content" style="height: 30px; margin-top: 20px;">
                                <h4>Delaware, USA</h4>
                                <!-- <p>Dhaka Metro, Dhaka, Bangladesh, 1200</p> -->
                            </div>
                        </div>
                    </div>
                    <div id="addressblock-1" v-if="locationTwo==true">
                        <div class="media r-g-card shadow">
                            <div class="icon-div"><img src="../../../../core/assets/images/icons/india.svg" alt="icon">
                            </div>
                            <div class="media-body-content" style="height: 30px; margin-top: 20px;">
                                <h4>Dhaka, Bangladesh</h4>
                                <!-- <p>Dhaka Metro, Dhaka, Bangladesh, 1200</p> -->
                            </div>
                        </div>
                    </div>
                    <div id="addressblock-1" v-if="locationThree==true">
                        <div class="media r-g-card shadow">
                            <div class="icon-div"><img src="../../../../core/assets/images/icons/amsterdam-monument-svgrepo-com.svg" alt="icon">
                            </div>
                            <div class="media-body-content" style="height: 30px; margin-top: 20px;">
                                <h4>Amsterdamn, Netherlands</h4>
                                <!-- <p>Dhaka Metro, Dhaka, Bangladesh, 1200</p> -->
                            </div>
                        </div>
                    </div>
                    <div id="addressblock-1" v-if="locationFour==true">
                        <div class="media r-g-card shadow">
                            <div class="icon-div"><img src="../../../../core/assets/images/icons/taj-mahal-svgrepo-com.svg" alt="icon">
                            </div>
                            <div class="media-body-content" style="height: 30px; margin-top: 20px;">
                                <h4>Bangalore , India</h4>
                                <!-- <p>Dhaka Metro, Dhaka, Bangladesh, 1200</p> -->
                            </div>
                        </div>
                    </div>
                    
                </div>
                <!-- // -->
            </div>
        </div>
    </section>
</template>

<script src="../js/contact.js">
</script>

<style scoped>
@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.blinking-text {
  animation: blink 1s infinite;
  color: #fe1515;
  font-weight: bold
}
@import url("../css/contact.css");
</style>