<template>
    <section class="sec-pad innerheadbg bg-fixd">
         <div class="container index-up">
            <div class="features row vcenter justify-content-between">
               <div class="col-lg-6 text-white">
                  <h2>Why Customers in over 15+ countries choose The Us?</h2>
                  <ul class="ullist check mt30">
                     <li>Flexible engagement models</li>
                     <li>24×7 Support across all timezones</li>
                     <li>Competitive pricing & ontime delivery</li>
                     <li>State of the art IT infrastructure</li>
                     <li>Strong technology competency</li>
                     <li>Seamless communication</li>
                  </ul>
               </div>
               <div class="col-lg-4 mmt40">
                  <div class="coutner-set-2 text-white">
                     <div class="counterdiv">
                        <p class="rg-num"><span>10</span>+</p>
                        <p>Years of<br> Experience</p>
                     </div>
                     <div class="counterdiv">
                        <p class="rg-num"><span>40</span>+</p>
                        <p>Team<br> Members</p>
                     </div>
                     <div class="counterdiv">
                        <p class="rg-num"><span>100</span>%</p>
                        <p>Customer<br> Satisfaction</p>
                     </div>
                     <div class="counterdiv">
                        <p class="rg-num"><span>120</span>+</p>
                        <p>Satisfied<br> Customers</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>    
</template>

<script src="../js/features.js">
</script>

<style scoped>
@import url("../css/features.css");
</style>