<template>
    <section class="demo-5-hero rg-bg-za">
         <div class="container-fluid heros">
            <div class="row vcenter justify-content-between">
               <div class="col-lg-6">
                  <div class="hero-content">
                    <span class="subtitleheading">✦ Solution-Based Service</span>
                    <h1 class="cd-headline"> We Create. Design. Develop. 
                        <b class="cd-words"> 
                            <!-- <b class="is-visible">Web Design</b> 
                            <b >App Design</b> 
                            <b>Digital Marketing</b> 
                            <b>Graphic Design</b> -->
                        </b> 
                    </h1>

                     <p class="mt30">Discover how you can apply your talent and skills to harness powerful change with our career-building student programs.</p>
                     <div class="pair-btn mt30">
                        <a v-on:click="services" href="#" class="r-g-btn r-g-btn-8">Get Started </a>
                        <a v-on:click="services" href="#" class="inine-btn">How It Works</a>
                     </div>
                  </div>
               </div>
               <div class="hero-b-img col-lg-6 mmt40">
                  <div class="sol-img">
                     <img src="../../../../core/assets/images/hero/hero-5.png" alt="img">
                  </div>
               </div>
            </div>
         </div>
      </section>
      
</template>

<script src="../js/hero.js">

</script>

<style>
    @import url("../css/hero.css");
    @import url("../css/responsive.css");
</style>